import { paths } from '@/generated/strapiApi';
import createClient from 'openapi-fetch';
import {
  StrapiArticleData,
  StrapiClassData,
  StrapiFooter,
  StrapiLoginForm,
  StrapiMainMenu,
  StrapiRegistrationForm,
  StrapiResetPasswordForm,
  StrapiPage,
  StrapiStartPage,
  StrapiCartModal,
  StrapiCartCheckout,
  StrapiPriceConfiguration,
  StrapiCheckoutPage,
  StrapiPasswordPage,
  StrapiProfilePage,
  StrapiOrderConfirmedPage,
  StrapiStorePage,
  StrapiStoreStaticData,
} from './strapiTypes';

export const BASE_URL =
  process.env.NEXT_PUBLIC_STRAPI_BASE_URL ?? 'STRAPI_BASE_URL_NOT_DEFINED';

const DEFAULT_FETCH_CONFIG = {
  revalidate: 3 * 3600, // revalidate content every 3 hours
};

const client = createClient<paths>({
  baseUrl: `${BASE_URL}/api`,
  headers: {
    Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_AUTH_TOKEN ?? ''}`,
  },
  next: DEFAULT_FETCH_CONFIG,
});

export async function getMainMenu(): Promise<StrapiMainMenu | undefined> {
  const { data } = await client.get('/main-menu', {
    params: {
      query: {
        fields: '*',
        populate: 'deep',
      },
    },
  });
  return data?.data;
}

export async function getFooter(): Promise<StrapiFooter | undefined> {
  const { data } = await client.get('/footer', {
    params: {
      query: {
        fields: '*',
        populate: 'deep',
      },
    },
  });
  return data?.data;
}

export async function getLoginForm(): Promise<StrapiLoginForm | undefined> {
  const { data } = await client.get('/login-form', {
    params: {
      query: {
        fields: '*',
        populate: 'deep',
      },
    },
  });
  return data?.data;
}

export async function getResetPasswordForm(): Promise<
  StrapiResetPasswordForm | undefined
> {
  const { data } = await client.get('/reset-password-form', {
    params: {
      query: {
        fields: '*',
        populate: 'deep',
      },
    },
  });
  return data?.data;
}

export async function getRegistrationForm(): Promise<
  StrapiRegistrationForm | undefined
> {
  const { data } = await client.get('/registration-form', {
    params: {
      query: {
        fields: '*',
        populate: 'deep',
      },
    },
  });
  return data?.data;
}

export async function getCartModal(): Promise<StrapiCartModal | undefined> {
  const { data } = await client.get('/cart-modal', {
    params: {
      query: {
        fields: '*',
        populate: 'deep',
      },
    },
  });
  return data?.data;
}

export async function getCartCheckout(): Promise<
  StrapiCartCheckout | undefined
> {
  const { data } = await client.get('/cart-checkout', {
    params: {
      query: {
        fields: '*',
        populate: 'deep',
      },
    },
  });
  return data?.data;
}

export async function getPriceConfiguration(): Promise<
  StrapiPriceConfiguration | undefined
> {
  const { data } = await client.get('/price-configuration', {
    params: {
      query: {
        fields: '*',
        populate: 'deep',
      },
    },
  });
  return data?.data;
}

export async function getStoreStaticData(): Promise<
  StrapiStoreStaticData | undefined
> {
  const { data } = await client.get('/store-static', {
    params: {
      query: {
        fields: '*',
        populate: 'deep',
      },
    },
  });
  return data?.data;
}

export async function getStartPage(): Promise<StrapiStartPage | undefined> {
  const { data } = await client.get('/start-page', {
    params: {
      query: {
        populate: 'deep,7',
      },
    },
  });
  return data?.data;
}

export async function getStorePage(): Promise<StrapiStorePage | undefined> {
  const { data } = await client.get('/store-page', {
    params: {
      query: {
        populate: 'deep,7',
      },
    },
  });
  return data?.data;
}

export async function getStartPageMetadata(): Promise<
  StrapiStartPage | undefined
> {
  const { data } = await client.get('/start-page', {
    params: {
      query: {
        fields: '*',
        populate: 'deep',
      },
    },
  });
  return data?.data;
}

export async function getCheckoutPage(): Promise<
  StrapiCheckoutPage | undefined
> {
  const { data } = await client.get('/checkout-page', {
    params: {
      query: {
        populate: 'deep,6',
      },
    },
  });
  return data?.data;
}

export async function getPasswordPage(): Promise<
  StrapiPasswordPage | undefined
> {
  const { data } = await client.get('/password-page', {
    params: {
      query: {
        populate: 'deep,6',
      },
    },
  });
  return data?.data;
}

export async function getProfilePage(): Promise<StrapiProfilePage | undefined> {
  const { data } = await client.get('/profile-page', {
    params: {
      query: {
        populate: 'deep,7',
      },
    },
  });
  return data?.data;
}

export async function getOrderConfirmedPage(): Promise<
  StrapiOrderConfirmedPage | undefined
> {
  const { data } = await client.get('/order-confirmed-page', {
    params: {
      query: {
        populate: 'deep,6',
      },
    },
  });
  return data?.data;
}

export async function getClass(
  slug: string
): Promise<StrapiClassData | undefined> {
  const { data } = await client.get('/classes', {
    params: {
      query: {},
    },
    querySerializer() {
      return `fields=*&populate=deep,6&filters[slug][$eq]=${slug}`;
    },
  });
  return data?.data?.[0];
}

export async function getAllClassesWithSlugs(): Promise<
  StrapiClassData[] | undefined
> {
  const { data } = await client.get('/classes', {
    params: {
      query: {
        'pagination[pageSize]': 500,
        fields: 'slug',
      },
    },
  });
  return data?.data;
}

export async function getClassMetadata(
  slug: string
): Promise<StrapiClassData | undefined> {
  const { data } = await client.get('/classes', {
    params: {
      query: {},
    },
    querySerializer() {
      return `?fields=meta&populate=deep&filters[slug][$eq]=${slug}`;
    },
  });
  return data?.data?.[0];
}

export async function getArticle(
  slug: string
): Promise<StrapiArticleData | undefined> {
  const { data } = await client.get('/articles', {
    params: {
      query: {},
    },
    querySerializer() {
      return `fields=*&populate=deep,6&filters[slug][$eq]=${slug}`;
    },
  });
  return data?.data?.[0];
}

export async function getAllArticlesWithSlugs(): Promise<
  StrapiArticleData[] | undefined
> {
  const { data } = await client.get('/articles', {
    params: {
      query: {
        'pagination[pageSize]': 500,
        fields: 'slug',
      },
    },
  });
  return data?.data;
}

export async function getArticleMetadata(
  slug: string
): Promise<StrapiArticleData | undefined> {
  const { data } = await client.get('/articles', {
    params: {
      query: {},
    },
    querySerializer() {
      return `?fields=meta&populate=deep&filters[slug][$eq]=${slug}`;
    },
  });
  return data?.data?.[0];
}

export async function getPage(slug: string): Promise<StrapiPage | undefined> {
  const { data } = await client.get('/pages', {
    params: {
      query: {},
    },
    querySerializer() {
      return `fields=*&populate=deep,7&filters[slug][$eq]=${slug}`;
    },
  });
  return data?.data?.[0];
}

export async function getAllPagesWithSlugs(): Promise<
  StrapiPage[] | undefined
> {
  const { data } = await client.get('/pages', {
    params: {
      query: {
        'pagination[pageSize]': 500,
        fields: 'slug',
      },
    },
  });
  return data?.data;
}

export async function getPageMetadata(
  slug: string
): Promise<StrapiPage | undefined> {
  const { data } = await client.get('/pages', {
    params: {
      query: {},
    },
    querySerializer() {
      return `?fields=meta&populate=deep&filters[slug][$eq]=${slug}`;
    },
  });
  return data?.data?.[0];
}
