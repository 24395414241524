import { StrapiFormSectionData } from '@/modules/strapiTypes';
import { ModalVisibility } from '@/strapiComponents/NavbarModal/NavbarModal';
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';

interface NavbarModalContextProps {
  navbarModalVisible: ModalVisibility;
  setNavbarModalVisible: (visibility: ModalVisibility) => void;
  formData: StrapiFormSectionData | undefined;
  setFormData: (formData: StrapiFormSectionData | undefined) => void;
}

const NavbarModalContext = createContext<NavbarModalContextProps | undefined>(
  undefined
);

const NavbarModalsContext: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [navbarModalVisible, setNavbarModalVisible] = useState<ModalVisibility>(
    ModalVisibility.None
  );
  const [formData, setFormData] = useState<StrapiFormSectionData>();

  useEffect(() => {
    if (formData) {
      setNavbarModalVisible(ModalVisibility.ContactForm);
    }
  }, [formData]);

  useEffect(() => {
    if (navbarModalVisible === ModalVisibility.None) {
      setFormData(undefined);
    }
  }, [navbarModalVisible]);

  return (
    <NavbarModalContext.Provider
      value={{
        navbarModalVisible,
        setNavbarModalVisible,
        formData,
        setFormData,
      }}
    >
      {children}
    </NavbarModalContext.Provider>
  );
};

export const useNavbarModal = () => {
  const context = useContext(NavbarModalContext);
  if (!context) {
    throw new Error('useNavbarModal must be used within a NavbarModalProvider');
  }
  return context;
};

export { NavbarModalsContext };
